import React, { useState, useEffect } from "react";
import { Pagination } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import API_URLS from "../../constants/apiUrls.ts";
import httpClient from "../../services/httpClient.ts";
import { capitalizeFirstLetter } from "../../services/commonFn";
import { useTranslation } from "react-i18next";
import { Loader } from "../../components/Loader.tsx";
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import { FaArrowUp, FaArrowDown, FaQuestionCircle } from "react-icons/fa";
import { PaginationTableColumns } from '../../constants/commonData.js'

const PaginationComponent = ({
  onBackClicked,
  item,
  province,
  city,
  levelOfInstruction,
  language,
  apprentice
}) => {

  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [colData, setColData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [duration, setDuration] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [languages, setLanguages] = useState(null)
  const [selectedDuration, setSelectedDuration] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedCity, setSelectedCity] = useState(city !== undefined ? city : "");
  const [selectedProgram, setSelectedProgram] = useState(levelOfInstruction !== undefined ? levelOfInstruction : "");
  const [selectedLanguage, setSelectedLanguage] = useState(language !== undefined ? language : "")
  const [showLoader, setShowLoader] = useState(false);
  const [tableSettings, setTableSettings] = useState(null)
  const [sortedData, setSortedData] = useState([])
  const [columnSortOrder, setColumnSortOrder] = useState(
    Array.from({ length: 7 }, (_, index) => ({
      index,
      asc: true
    }))
  );

  const handleProvinceChange = (e) => {
    const { value } = e.target
    setSelectedProvince(value);
    setSelectedCity("");
    setSelectedProgram("");
    setDuration("");
    setSelectedLanguage("")
    setCurrentPage(1);
  };

  const handleCityChange = (e) => {
    const { value } = e.target;
    setSelectedCity(value);
    setCurrentPage(1)
  };

  // Change page
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // const changeDuration = (str) => {
  //   if(str) {
  //     const splitArray = str.split(" ")
  //     const conversionDuration = capitalizeFirstLetter(t(`educationalProgram.tabProvinceAndTerritory.paginationComponent.durationValues.${splitArray[splitArray.length -1]}`))
  //     return splitArray.length == 1 ? capitalizeFirstLetter(str) : splitArray[0] + " " + conversionDuration
  //   }
  // }

  // const changeDuration = (str) => {
  //   if (!str || str.toLowerCase() === 'n/a') return str;

  //   // Helper function to translate and capitalize
  //   const translateAndCapitalize = (key) => 
  //     capitalizeFirstLetter(t(`educationalProgram.tabProvinceAndTerritory.paginationComponent.durationValues.${key}`));

  //   // Split the string into parts
  //   let splitArray = str.split(' ');

  //   // Check for 'or' cases and translate only 'or' and the unit
  //   if (str.includes(' or ')) {
  //     return splitArray
  //       .map((word) => {
  //         if (word.toLowerCase() === 'or') {
  //           return t("or");
  //         } else if (isNaN(word)) {
  //           return translateAndCapitalize(word);
  //         } else {
  //           return word; // Keep numbers and other non-translatable parts as they are
  //         }
  //       })
  //       .join(' ');
  //   }

  //   // Handle cases like "hours/week" or "hours per semester"
  //   if (str.includes('/')) {
  //     const [value, unit] = str.split('/');
  //     const translatedUnit = unit
  //       .split(' ')
  //       .map((word) => translateAndCapitalize(word))
  //       .join(' ');

  //     return value + '/' + translatedUnit;
  //   }

  //   // Handle cases like "1 semester (3 hours/week)"
  //   if (str.includes('(') && str.includes(')')) {
  //     const [mainPart, subPart] = str.split('(');
  //     const translatedSubPart = subPart
  //       .replace(')', '')
  //       .split('/')
  //       .map((word) => translateAndCapitalize(word.trim()))
  //       .join('/');

  //     return mainPart.trim() + ' (' + translatedSubPart + ')';
  //   }

  //   // Handle normal cases, translate only the last word if it's not a number
  //   const lastWord = splitArray.pop();
  //   const translatedLastWord = isNaN(lastWord) ? translateAndCapitalize(lastWord) : lastWord;
  //   const firstPart = splitArray.join(' ');

  //   return firstPart ? `${firstPart} ${translatedLastWord}` : translatedLastWord;
  // };

  const changeDuration = (str) => {
    if (!str || str.toLowerCase() === 'n/a') return str;

    // Helper function to translate and capitalize
    const translateAndCapitalize = (key) =>
      capitalizeFirstLetter(t(`educationalProgram.tabProvinceAndTerritory.paginationComponent.durationValues.${key}`));

    // Handle 'or' cases
    if (str.includes(' or ')) {
      return str
        .split(' or ')
        .map(part => part.split(' ')
          .map(word => {
            if (word.toLowerCase() === 'or') return t('or');
            return isNaN(word) ? translateAndCapitalize(word) : word;
          })
          .join(' ')
        )
        .join(' or ');
    }

    // Handle cases with parentheses
    if (str.includes('(') && str.includes(')')) {
      const [mainPart, subPart] = str.split('(');
      const translatedMainPart = mainPart
        .trim()
        .split(' ')
        .map(word => isNaN(word) ? translateAndCapitalize(word) : word)
        .join(' ');

      // Process the subPart separately
      const translatedSubPart = subPart
        .replace(')', '')
        .split('/')
        .map(word => {
          const parts = word.split(" ");
          return isNaN(parts?.[0]) ? translateAndCapitalize(word) : parts?.[0] + " " + translateAndCapitalize(parts?.[1])
        })
        .join('/');

      return `${translatedMainPart} (${translatedSubPart})`;
    }

    // Handle cases with '/': split into value and unit parts
    if (str.includes('/')) {
      const [valuePart, unitPart] = str.split('/');
      const translatedValuePart = valuePart
        .split(' ')
        .map(word => isNaN(word) ? translateAndCapitalize(word) : word)
        .join(' ');

      const translatedUnitPart = unitPart
        .split(' ')
        .map(word => translateAndCapitalize(word.trim()))
        .join(' ');

      return `${translatedValuePart}/${translatedUnitPart}`;
    }



    // Handle normal cases where we translate only the last word
    const splitArray = str.split(' ');
    const lastWord = splitArray.pop();
    const translatedLastWord = isNaN(lastWord) ? translateAndCapitalize(lastWord) : lastWord;
    const firstPart = splitArray.join(' ');

    return firstPart ? `${firstPart} ${translatedLastWord}` : translatedLastWord;
  };

  // Example usage:
  // console.log(changeDuration('1 semester (6 hours/week)'));
  // Expected output: '1 <translated "semester"> (6 <translated "hours">/<translated "week">)'


  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSorting = (sortingOrder) => {
    const sortingData = [...colData];

    sortingData.sort((a, b) => {
      for (let { index, asc } of sortingOrder) {
        const valueA = a[index] ?? '';
        const valueB = b[index] ?? '';

        // Compare the values
        const comparison = valueA.localeCompare(valueB);

        // If values are not equal, return the comparison result
        if (comparison !== 0) {
          return asc ? comparison : -comparison;
        }
      }

      // If all values are equal, return 0
      return 0;
    });

    return sortingData;
  };

  const toggleSortOrder = (index) => {
    setColumnSortOrder(prevOrder => prevOrder.map(col =>
      col.index === index ? { ...col, asc: !col.asc } : col
    ));
    const sortingOrder = columnSortOrder.map(col => col.index === index ? { ...col, asc: !col.asc } : col)
    setSortedData(handleSorting(sortingOrder))
  };

  // Render page numbers
  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };

  const onBack = () => {
    onBackClicked();
  };

  const generateColumns = (length, labels, fields, visibility) => {
    if (length !== labels.length || length !== fields.length || length !== visibility.length) {
        throw new Error('Length of labels, fields, and visibility arrays must be equal');
    }

    return new Array(length).fill(null).map((_, index) => ({
        field: fields[index],
        label: labels[index],
        visible: visibility[index],
    }));
};

  const fetchData = (province) => {
    let url;
    url = API_URLS.educationalListPagination
      .replace("{fieldOfStudy}", item === "all" ? "all" : item._id || item.id)
      .replace("{province}", province)
      .replace("{city}", selectedCity)
      .replace("{program}", selectedProgram)
      .replace("{duration}", selectedDuration)
      .replace("{apprentice}", apprentice ? apprentice : "")
      .replace("{language}", selectedLanguage)
      .replace("{page}", currentPage)
      .replace("{limit}", itemsPerPage);

    httpClient({
      method: "get",
      url,
    }).then(({ result, error }) => {

      if (result) {

        const resArr = [];
        for (let r in result.studyPrograms) {
          let temparr = new Array(7).fill(0);
          temparr[0] = capitalizeFirstLetter(
            result.studyPrograms[r].institution.name
          );
          temparr[1] = capitalizeFirstLetter(t(result.studyPrograms[r].name));
          temparr[2] = changeDuration(result.studyPrograms[r].length)
          temparr[3] = capitalizeFirstLetter(result.studyPrograms[r].language);
          temparr[4] = capitalizeFirstLetter(
            t(result.studyPrograms[r].languageKey)
          );
          temparr[5] = capitalizeFirstLetter(
            t(result.studyPrograms[r].institution.city.languageKey)
          );
          temparr[6] = capitalizeFirstLetter(
            t(result.studyPrograms[r].institution.city.province.languageKey)
          );
          temparr[7] = result.studyPrograms[r].url;
          resArr[r] = temparr;
        }
        const duration = new Set(
          result.studyPrograms.map((item) => item.length)
        );
        const durationValues = [...duration].sort();

        setDuration([
          ...new Set(
            durationValues &&
            durationValues.map((item) =>
              capitalizeFirstLetter(
                isNaN(item.split("")[0])
                  ? item.split("")[0]
                  : item.split(" ")[1]
              )
            )
          ),
        ]);

        setCities(result?.cities);
        setDuration(result?.durations);
        setPrograms(result?.programs);
        setTotalPages(result.totalPages);
        setColData(resArr);
        setSortedData(resArr)
        setShowLoader(false);
      }
    });
  };

  useEffect(() => {
    const fetch = async () => {
      setShowLoader(true);
      let urls = {
        geographies: API_URLS.listGeographies,
        studyProgramsLanguage: API_URLS.listStudyProgramsLanguage,
        tableSettingsResponse: API_URLS.getTableSettings,
      }
      const [geographiesResponse, studyProgramsLanguageResponse, tableSettingsResponse] = await Promise.all([
        httpClient({ method: "get", url: urls.geographies }),
        httpClient({ method: "get", url: urls.studyProgramsLanguage }),
        httpClient({ method: "get", url: urls.tableSettingsResponse }),
      ]);
     
      if (studyProgramsLanguageResponse.result) {
        setLanguages(studyProgramsLanguageResponse.result.results)
      }
      if (tableSettingsResponse.result.status) {
        setTableSettings(tableSettingsResponse?.result?.response?.tableSettings?.educationInsights?.paginationTable)
      }else{
        const paginationTableVisibility = new Array(PaginationTableColumns.labels.length).fill(true); // Column visibility based on initial status
        
                const columns = generateColumns(PaginationTableColumns.labels.length, PaginationTableColumns.labels, PaginationTableColumns.fields, paginationTableVisibility);
        const data = {
          tableName: "paginationTable",
          settings: columns
      }
      httpClient({
          method: "post",
          url: API_URLS.addTableSettings,
          data: JSON.stringify(data),
      })
          .then(({ result, error }) => {
              if(result.status) {
                setTableSettings(result?.response?.tableSettings?.educationInsights?.paginationTable)
              }
          })
      }
      if (geographiesResponse.result) {
        const result = geographiesResponse.result
        const filteredGeographies = result?.geographies
          .filter(
            (item) => item.name !== "geography" && item.name !== "canada"
          )
          .sort((a, b) => a.name.localeCompare(b.name));
        setProvinces(filteredGeographies);
        if (!selectedProvince) {
          const foundProvince = result?.geographies.find(
            (item) => item.name === province?.toLowerCase()
          );
          setSelectedProvince(foundProvince ? foundProvince?._id : province);
          fetchData(foundProvince ? foundProvince?._id : province);
        } else {
          fetchData(selectedProvince);
        }
      }

    }

    fetch();
  }, [
    selectedDuration,
    selectedProgram,
    currentPage,
    selectedCity,
    item,
    province,
    selectedLanguage,
    selectedProvince,
    t
  ]);

  return (
    <div>
      <Loader show={showLoader} />
      {/* <button
        onClick={onBack}
        className="btn btn-link text-theme font-small ps-2 m-0"
      >
        {showTable
          ? t(
            "educationalProgram.tabProvinceAndTerritory.paginationComponent.backButtonTextTable"
          )
          : t(
            "educationalProgram.tabProvinceAndTerritory.paginationComponent.backButtonTextGeography"
          )}
      </button> */}
      {/* <caption className="ps-2  caption_text">
        {capitalizeFirstLetter(t(item.languageKey))}
      </caption> */}
      <div className="row gx-0">
        <div className="col-md-3">
          <div className="custom-form-group custom-form-group-sm ps-2">
            <label htmlFor="yearSelect" className="text-black">
              {t(
                "educationalProgram.tabProvinceAndTerritory.paginationComponent.inputTitle.selectProvince"
              )}
            </label>
            <select
              className="form-select c-form-control c-form-control-bg c-form-control-sm c-form-control-sm-height fw-semibold"
              value={selectedProvince}
              onChange={handleProvinceChange}
            >
              <option value=" " selected>
                {t(
                  "educationalProgram.tabProvinceAndTerritory.paginationComponent.inputValue.all"
                )}
              </option>
              {provinces &&
                provinces.map((item) => (
                  <option key={item._id} value={item._id}>
                    {capitalizeFirstLetter(t(item.languageKey))}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="col-md-3">
          <div className="custom-form-group custom-form-group-sm ps-2">
            <label htmlFor="citySelect" className="text-black">
              {t(
                "educationalProgram.tabProvinceAndTerritory.paginationComponent.inputTitle.selectCity"
              )}
            </label>
            <select
              className="form-select c-form-control c-form-control-bg c-form-control-sm c-form-control-sm-height fw-semibold"
              value={selectedCity}
              onChange={handleCityChange}
            >
              <option value={""} selected>
                {t(
                  "educationalProgram.tabProvinceAndTerritory.paginationComponent.inputValue.all"
                )}
              </option>
              {cities.map((item) => (
                <option key={item._id} value={item?._id}>
                  {capitalizeFirstLetter(item?.name)}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-2">
          <div className="custom-form-group custom-form-group-sm ps-2">
            <label htmlFor="programSelect" className="text-black">
              {t(
                "educationalProgram.tabProvinceAndTerritory.paginationComponent.inputTitle.selectProgram"
              )}
            </label>
            <select
              className="form-select c-form-control c-form-control-bg c-form-control-sm c-form-control-sm-height fw-semibold"
              value={selectedProgram}
              onChange={(e) => setSelectedProgram(e.target.value)}
            >
              <option value="" selected>
                {t(
                  "educationalProgram.tabProvinceAndTerritory.paginationComponent.inputValue.all"
                )}
              </option>
              {programs &&
                programs.map((item) => (
                  <option value={item}>{capitalizeFirstLetter(t(item))}</option>
                ))}
            </select>
          </div>
        </div>
        <div className="col-md-2">
          <div className="custom-form-group custom-form-group-sm ps-2">
            <label htmlFor="durationSelect" className="text-black">
              {t(
                "educationalProgram.tabProvinceAndTerritory.paginationComponent.inputTitle.selectDuration"
              )}
            </label>
            <select
              className="form-select c-form-control c-form-control-bg c-form-control-sm c-form-control-sm-height fw-semibold"
              value={selectedDuration}
              onChange={(e) => setSelectedDuration(e.target.value)}
            >
              <option value="" selected>
                {t(
                  "educationalProgram.tabProvinceAndTerritory.paginationComponent.inputValue.all"
                )}
              </option>
              {duration &&
                duration.map((item) => (
                  <option value={item}>
                    {capitalizeFirstLetter(
                      t(
                        "educationalProgram.tabProvinceAndTerritory.paginationComponent.durationValues." +
                        item
                      )
                    )}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="col-md-2">
          <div className="custom-form-group custom-form-group-sm ps-2">
            <label htmlFor="languageSelect" className="text-black">
              {t(
                "educationalProgram.tabProvinceAndTerritory.paginationComponent.inputTitle.selectLanguage"
              )}
            </label>
            <select
              className="form-select c-form-control c-form-control-bg c-form-control-sm c-form-control-sm-height fw-semibold"
              value={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.target.value)}
            >
              <option value={""} selected>
                {t(
                  "educationalProgram.tabProvinceAndTerritory.paginationComponent.inputValue.all"
                )}
              </option>
              {languages && languages.length > 0 && languages.map((item) => (
                <option key={item} value={item}>
                  {capitalizeFirstLetter(t(`language.${item}`))}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {colData && colData.length > 0 ? (
        <>
          <Table responsive striped bordered className="m-0 table">
            <thead>
              <tr>
                <th
                  style={{
                    borderRadius: "5px 0 0 0",
                    position: "relative", // Position relative to place the arrow absolutely
                    textAlign: "center", // Center the text
                    padding: "0 10px", // Add padding for better spacing
                    display: tableSettings?.[0].visible ? "table-cell" : "none",
                  }}
                  onClick={() => toggleSortOrder(0)}
                >
                  <span style={{ display: 'inline-block', width: '100%' }}>
                    {t(
                      "educationalProgram.tabProvinceAndTerritory.paginationComponent.tableColumnHeading.InstitutionName"
                    )}
                  </span>
                  {columnSortOrder[0].asc ? (
                    <span style={{
                      position: 'absolute',
                      right: '10px', // Adjust this value to suit your padding
                      top: '50%',
                      transform: 'translateY(-50%)',
                      background: '#f8f8f8',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '20px',
                      height: '20px'
                    }}>
                      <FaArrowUp style={{ fontSize: "10px", color: '#000' }} />
                    </span>
                  ) : (
                    <span style={{
                      position: 'absolute',
                      right: '10px', // Adjust this value to suit your padding
                      top: '50%',
                      transform: 'translateY(-50%)',
                      background: '#f8f8f8',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '20px',
                      height: '20px'
                    }}>
                      <FaArrowDown style={{ fontSize: "10px", color: '#000' }} />
                    </span>
                  )}
                </th>
                <th
                  style={{
                    textAlign: "center",
                    display: tableSettings?.[1].visible ? "table-cell" : "none",
                  }}
                >
                  {t(
                    "educationalProgram.tabProvinceAndTerritory.paginationComponent.tableColumnHeading.ProgramName"
                  )}
                </th>
                <th
                  style={{
                    textAlign: "center",
                    display: tableSettings?.[2].visible ? "table-cell" : "none",
                  }}
                >
                  {t(
                    "educationalProgram.tabProvinceAndTerritory.paginationComponent.tableColumnHeading.Duration"
                  )}
                </th>
                <th
                  style={{
                    textAlign: "center",
                    display: tableSettings?.[3].visible ? "table-cell" : "none",
                  }}
                >
                  {t(
                    "educationalProgram.tabProvinceAndTerritory.paginationComponent.tableColumnHeading.Language"
                  )}
                </th>
                <th
                  style={{
                    textAlign: "center",
                    display: tableSettings?.[4].visible ? "table-cell" : "none",
                  }}
                >
                  {t(
                    "educationalProgram.tabProvinceAndTerritory.paginationComponent.tableColumnHeading.Level"
                  )}
                </th>
                <th
                  style={{
                    textAlign: "center",
                    display: tableSettings?.[5].visible ? "table-cell" : "none",
                  }}
                >
                  {t(
                    "educationalProgram.tabProvinceAndTerritory.paginationComponent.tableColumnHeading.City"
                  )}
                </th>
                <th
                  style={{
                    textAlign: "center",
                    display: tableSettings?.[6].visible ? "table-cell" : "none",
                  }}
                >
                  {t(
                    "educationalProgram.tabProvinceAndTerritory.paginationComponent.tableColumnHeading.Province"
                  )}
                </th>
                <th
                  style={{
                    textAlign: "center",
                    borderRadius: "0 5px 0 0",
                    display: tableSettings?.[7].visible ? "table-cell" : "none",
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <span style={{marginLeft:"30px"}}>
                    {t(
                    "educationalProgram.tabProvinceAndTerritory.paginationComponent.tableColumnHeading.URL"
                  )}
                    </span>
                    <OverlayTrigger
                                    delay={{ hide: 450, show: 300 }}
                                    overlay={(props) => (
                                        <Tooltip {...props} className="tooltip-justify">
                                            {/* <div style={{background: "#fff", borderRadius: "15px", width: "100%", padding:"15px", marginBottom:"5px"}}>
                                                <img src={FPSCLogo} alt="" width={"100%"} height={100}/>
                                            </div> */}
                                            
                                                {t("urlInformationText").split('\\n').map((line, index, array) => (
                                                    <React.Fragment key={index}>
                                                        {line}
                                                        {index < array.length - 1 && (
                                                            <div style={{ height: '7px' }} />)}
                                                    </React.Fragment>
                                                ))}
                                            
                                        </Tooltip>
                                    )}
                                    placement="bottom"
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: '4px',
                                            width: "25px",
                                            height: "25px",
                                            backgroundColor: "#f0f0f0",
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <FaQuestionCircle style={{ fontSize: '15px', color: "#212529" }} />
                                    </div>
                                </OverlayTrigger>
                    </div>
                 
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedData?.map((i, index1) => {
                return (
                  <tr key={index1}>
                    {i?.map((item, index) => {
                      const columnSettings = tableSettings?.[index] || {};
                       if (columnSettings.visible === false) {
                        return null; // Do not render this <td> if visible is false
                    }
                      return (
                        <td
                          key={index}
                          style={{
                            cursor: index === i.length - 1 ? "pointer" : "",
                            textAlign: !(index === 0) ? "center" : "left",
                          }}
                        >
                          {index === i.length - 1 ? (
                            <a target="_blank" href={item} rel="noreferrer">
                              {"View Website"}
                            </a>
                          ) : (
                            item
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      ) : (
        <div className="text-danger mt-3 ms-2">
          {t('educationalProgram.tabProvinceAndTerritory.paginationComponent.noDataMessage')}
        </div>
      )}
      {!!colData.length && (
        <Pagination className={"custom-pagination pagination-sm"}>
          <Pagination.Prev onClick={prevPage} disabled={currentPage === 1} />
          {renderPageNumbers()}
          <Pagination.Next
            onClick={nextPage}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
    </div>
  );
};

export default PaginationComponent;
