import React, { useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaQuestionCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

interface CommonHeaderProps {
    title: string;
    subtitle?: string;
    buttonTitle?: string | "";
    ButtonIcon?: React.ReactNode;
    buttonEventTriggered?: () => void;
    helpText?: string | "";
    component?: string | "";
  }

const CommonHeader: React.FC<CommonHeaderProps> = ({ title, subtitle, buttonTitle = "", ButtonIcon, buttonEventTriggered, helpText, component }) => {
    const { t } = useTranslation()
    const [selected, setSelected] = useState(0);
    const decodedToken = jwtDecode(localStorage.getItem("token") || "");
    const excludedComponents = ["documents", "addUser"];

    return (
        <>
            <header className="c_header">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <h2>{title}</h2>

                    </div>
                    <div className="col-md-6 text-end d-flex align-items-center justify-content-end gap-2 row-padding-right">
                        {buttonTitle && (
                            <button
                                className="button"
                                onClick={buttonEventTriggered}
                            >
                                {typeof ButtonIcon === 'string' ? (
                                    <img src={ButtonIcon} className="inline-block align-middle me-2 icon-margin" alt="icon" />
                                ) : (
                                    <span style={{ marginRight: '8px', fontSize: '15px' }}>{ButtonIcon}</span>
                                )}
                                {buttonTitle}
                            </button>
                        )}
                        {subtitle && <h6>{subtitle}</h6>}
                        {!excludedComponents.includes(component as string) && (
                            <>
                                <div className="vertical-line"></div>
                                <OverlayTrigger
                                    delay={{ hide: 450, show: 300 }}
                                    overlay={(props) => (
                                        <Tooltip {...props} className="tooltip-justify">
                                            {/* <div style={{background: "#fff", borderRadius: "15px", width: "100%", padding:"15px", marginBottom:"5px"}}>
                                                <img src={FPSCLogo} alt="" width={"100%"} height={100}/>
                                            </div> */}
                                            
                                                {t(helpText as string).split('\\n').map((line, index, array) => (
                                                    <React.Fragment key={index}>
                                                        {line}
                                                        {index < array.length - 1 && (
                                                            <div style={{ height: '3px' }} />)
                                                            }
                                                    </React.Fragment>
                                                ))}
                                            
                                        </Tooltip>
                                    )}
                                    placement="bottom"
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: '4px',
                                            width: "40px",
                                            height: "40px",
                                            backgroundColor: "#f0f0f0",
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <FaQuestionCircle style={{ fontSize: '20px' }} />
                                    </div>
                                </OverlayTrigger>
                            </>
                        )}
                    </div>
                </div>
            </header>
        </>
    );
}

export default CommonHeader;