// i18n.js
// import React, {useState, useEffect} from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import i18nHttpLoader from 'i18next-http-backend';
import enTranslation from '../translation/locales/en/translation.json';
import frTranslation from '../translation/locales/fr/translation.json';
import API_URLS from '../constants/apiUrls.ts';
import httpClient from '../services/httpClient.ts';

// const [language, setLanguage] = useState()
// useEffect(() => {
  
//   setLanguage(language)
// },[localStorage.getItem('selectedLanguage')])

let language = localStorage.getItem('selectedLanguage')
let url = API_URLS.getTranslationFile.replace
// const fetchTranslations = async (lang) => {
//   const response = await fetch(`http://localhost:5000/translations/${lang}`);
//   return response.json();
// };

i18n
  // .use(i18nHttpLoader)
  .use(initReactI18next)
  .init({
    // resources: {
    //   en: {
    //     translation: enTranslation
    //     ,
    //   },
    //   fr: {
    //     translation: frTranslation
    //   },
    // },
    lng: 'en', // default language
    fallbackLng: 'en', // fallback language
    load: 'currentOnly',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    resources:{}
  });

  i18n.changeLanguage(language);

export default i18n;
