import React, { useEffect, useState, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import API_URLS from "../../constants/apiUrls.ts";
import httpClient from "../../services/httpClient.ts";
import PaginationComponent from "./PaginationComponent.js";
import { useTranslation } from "react-i18next";
import { capitalizeString } from "../../services/commonFn";
import { Loader } from "../Loader.tsx";
import { FaInfoCircle, FaQuestionCircle } from "react-icons/fa";
import { Row, OverlayTrigger, Tooltip } from "react-bootstrap";

const HorizontalChartEducationalProgram = () => {
  const { t } = useTranslation()
  const chartRef = useRef(null)
  const [showLoader, setShowLoader] = useState(false)
  const [provinces, setProvinces] = useState([])
  const [cities, setCities] = useState([])
  const [levelOfInstructions, setLevelOfInstructions] = useState([])
  const [selectedProvince, setProvince] = useState()
  const [selectedLevelOfInstruction, setLevelOfInstruction] = useState("")
  const [apprenticeOffered, setApprenticeOffered] = useState("")
  const [showPaginationComponent, setShowPaginationComponent] = useState(false)
  const [studyField, setStudyField] = useState("")
  const [initialLoading, setInitialLoading] = useState(true)
  const [key, setKey] = useState(0);
  const [options, setOptions] = useState({
    chart: {
      type: "bar",
    },
    title: {
      text: '',
      align: "left",
    },
    xAxis: {
      categories: [],
      // labels: {
      //   style: {
      //     fontWeight: "bold",
      //   },
      // },
    },
    yAxis: {
      visible: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      // useHTML: true,
      // formatter: function () {
      //   const studyField = capitalizeString(t(this.point.item.studyField));
      //   const programs = this.point.y;
      //   return `<b>${studyField}<br/>${t("programs")}: ${programs}</b><br/><h4 style='font-weight:bold'>${t("clickForProgramDetails")}</h4>`;
      // }
      formatter: function () {
        if (this.point.item.studyField && this.point.y) {
          return `<b>${capitalizeString(t(this.point.item.studyField))}<br/>${t("programs")}: ${this.point.y} </b><br/><b style='font-weight:bold'>${t("clickForProgramDetails")}</b>`;
        }
        return '';
      },
    },
    series: [
      {
        name: "Program(s)",
        data: [],
        pointWidth: 20,
        borderRadius: 10,
        color: "#E61E28",
        dataLabels: {
          enabled: true,
          inside: true,
          format: "{y}",
          style: {
            color: "white",
            textOutline: "0px",
          },
        },
        tooltip: {
          pointFormat:
            "<b>{point.name}<br/>" +
            `${t("programs")}: ` +
            `{point.y}` +
            "</b><br/>" +
            `<h4 style='font-weight:bold'>${t("clickForProgramDetails")}</h4>`
        }
      },
    ],
    plotOptions: {
      series: {
        point: {
          events: {
            click: function () {
              setShowPaginationComponent(!showPaginationComponent);
              setStudyField(this.item);
            },
          },
        },
        cursor: "pointer"
      },
    },
  })

  useEffect(() => {
    try {
      const fetch = async () => {
        // Define all the API URLs
        setShowLoader(true)
        try {
          const urls = {
            geographies: API_URLS.listGeographies,
            countAreaOfStudy: API_URLS.countAreaOfStudy.replace('{province}', '').replace('{levelOfInstruction}', '').replace('{apprentice}', ''),
            levelOfInstruction: API_URLS.listLevelOfInstruction
          };


          // Execute all API calls concurrently
          const [geographiesResponse, levelOfInstructionResponse, countAreaOfStudyResponse] = await Promise.all([
            httpClient({ method: "get", url: urls.geographies }),
            httpClient({ method: "get", url: urls.levelOfInstruction }),
            // httpClient({ method: "get", url: urls.countAreaOfStudy })
          ]);

          // Handle geographies response
          if (geographiesResponse?.result) {
            setProvinces(geographiesResponse?.result?.geographies.filter(item => item.name !== 'canada' && item.name !== 'geography').sort((a, b) => a.name.localeCompare(b.name)))
          }
          if (levelOfInstructionResponse.result) {
            setLevelOfInstructions(levelOfInstructionResponse.result.results)
          }
          // if (countAreaOfStudyResponse.result) {
          //   const result = countAreaOfStudyResponse.result.counts.sort((a, b) => a.count < b.count ? 1 : -1)
          //   const categories = result.map(item => capitalizeString(t(item.studyField)))
          //   const data = result.map(item => { return { y: item.count, item } })
          //   if (data && data.length > 0 && categories && categories.length > 0) {
          //     setOptions(prevOptions => {
          //       const updatedOptions = {
          //         ...prevOptions,
          //         xAxis: {
          //           ...prevOptions.xAxis,
          //           categories: categories
          //         },
          //         series: [
          //           {
          //             ...prevOptions.series[0],
          //             data: data,
          //           },
          //         ],
          //       };
          //       // console.log("Updated options: ", updatedOptions);
          //       return updatedOptions;
          //     });
          //   }

          // }
        } catch (error) {
          console.log(error)
        } finally {
          setShowLoader(false)
        }
      }
      fetch()
    } catch (error) {
      console.log(error)
    } finally {
      setInitialLoading(false)
    }

  }, [])

  useEffect(() => {
    // if (!initialLoading) {
    try {
      setShowLoader(true)
      const url = API_URLS.countAreaOfStudy.replace('{province}', selectedProvince).replace('{levelOfInstruction}', selectedLevelOfInstruction).replace('{apprentice}', apprenticeOffered)

      httpClient({
        method: "get",
        url,
      }).then(({ result, error }) => {
        if (result) {
          const res = result.counts.sort((a, b) => a.count < b.count ? 1 : -1)
          const categories = res.map(item => capitalizeString(t(item.studyField)))
          const data = res.map(item => { return { y: item.count, item } })

          setOptions(prevOptions => ({
            ...prevOptions,
            xAxis: {
              categories: categories
            },
            tooltip: {
              // useHTML: true,
              // shadow: true,
              // backgroundColor: "#fff",
              formatter: function () {
                if (this.point) {
                  return `<b>${capitalizeString(t(this?.point?.item?.studyField))}<br/>${t("programs")}: ${this?.point?.y} </b><br/><p style='font-weight:bold'>${t("clickForProgramDetails")}</p>`
                }
              },
            },
            series: [
              {
                ...prevOptions.series[0],
                data: data,
              },
            ],
          }))
        }
      })
    } catch (error) {
      console.log(error)
    } finally {
      setShowLoader(false)
    }
    // }

  }, [selectedProvince, selectedLevelOfInstruction, apprenticeOffered, window.location.href, t])

  useEffect(() => {
    if (chartRef.current && chartRef.current.chart) {
      chartRef.current.chart.reflow();
    }
  }, [window.location.href, options]);

  // useEffect(() => {
  //   if (!initialLoading) {
  //     // Trigger a re-render or update after options are fully set
  //     setOptions(prevOptions => ({
  //       ...prevOptions,
  //       chart: {
  //         ...prevOptions.chart,
  //         events: {
  //           load: function () {
  //             this.reflow(); // Ensure the chart reflows correctly
  //           }
  //         }
  //       }
  //     }));
  //   }
  // }, [initialLoading]);

  return (
    <>
      <Loader show={showLoader} />
      <Row>
        <div className="d-flex justify-content-between">
          <h6 className="py-3 px-2">
            {t('educationalProgram.tabAreaOfStudy.title')}
          </h6>
          <div className="d-flex gap-1">
            <OverlayTrigger
              delay={{ hide: 450, show: 300 }}
              overlay={(props) => (
                <Tooltip {...props} className="tooltip-justify">
                  {/* <div style={{background: "#fff", borderRadius: "15px", width: "100%", padding:"15px", marginBottom:"5px"}}>
                                                <img src={FPSCLogo} alt="" width={"100%"} height={100}/>
                                            </div> */}
                  <b>{t("disclaimer")}: </b> {t("disclamerTextAreaOfStudy").split('\\n').map((line, index, array) => (
                    <React.Fragment key={index}>
                      {line}
                      {index < array.length - 1 && (
                        <div style={{ height: '7px' }} />)}
                    </React.Fragment>
                  ))}


                </Tooltip>
              )}
              placement="bottom"
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '4px',
                  width: "40px",
                  height: "40px",
                  backgroundColor: "#f0f0f0",
                  cursor: 'pointer',
                  // marginRight: "10px"
                }}
              >
                <FaInfoCircle style={{ fontSize: '20px' }} />
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              delay={{ hide: 450, show: 300 }}
              overlay={(props) => (
                <Tooltip {...props} className="tooltip-justify">
                  {/* <div style={{background: "#fff", borderRadius: "15px", width: "100%", padding:"15px", marginBottom:"5px"}}>
                                                <img src={FPSCLogo} alt="" width={"100%"} height={100}/>
                                            </div> */}

                  {t("helpTextAreaOfStudy").split('\\n').map((line, index, array) => (
                    <React.Fragment key={index}>
                      {line}
                      {index < array.length - 1 && (
                        <div style={{ height: '7px' }} />)}
                    </React.Fragment>
                  ))}

                </Tooltip>
              )}
              placement="bottom"
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '4px',
                  width: "40px",
                  height: "40px",
                  backgroundColor: "#f0f0f0",
                  cursor: 'pointer',
                  marginRight: "10px"
                }}
              >
                <FaQuestionCircle style={{ fontSize: '20px' }} />
              </div>
            </OverlayTrigger>
          </div>
        </div>
      </Row>
      {!showPaginationComponent && (
        <div className="row gx-0">
          <div className="col-md-3">
            <div className="custom-form-group custom-form-group-sm ps-2">
              <label htmlFor="yearSelect" className="text-black">
                {t(
                  "educationalProgram.tabProvinceAndTerritory.paginationComponent.inputTitle.selectProvince"
                )}
              </label>
              <select
                className="form-select c-form-control c-form-control-bg c-form-control-sm c-form-control-sm-height fw-semibold"
                value={selectedProvince}
                onChange={(e) => setProvince(e.target.value)}
              >
                <option value="" selected>
                  {t(
                    "educationalProgram.tabProvinceAndTerritory.paginationComponent.inputValue.all"
                  )}
                </option>
                {provinces &&
                  provinces.map((item) => (
                    <option key={item._id} value={item._id}>
                      {capitalizeString(t(item.languageKey))}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <div className="col-md-3">
            <div className="custom-form-group custom-form-group-sm ps-2">
              <label htmlFor="levelOfInstructionSelect" className="text-black">
                {t(
                  "educationalProgram.tabAreaOfStudy.inputTitle.selectLevelOfInstruction"
                )}
              </label>
              <select
                className="form-select c-form-control c-form-control-bg c-form-control-sm c-form-control-sm-height fw-semibold"
                value={selectedLevelOfInstruction}
                onChange={(e) => setLevelOfInstruction(e.target.value)}
              >
                <option value="" selected>
                  {t(
                    "educationalProgram.tabProvinceAndTerritory.paginationComponent.inputValue.all"
                  )}
                </option>
                {levelOfInstructions &&
                  levelOfInstructions.map((item) => (
                    <option value={item}>{capitalizeString(t(item))}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <div className="custom-form-group custom-form-group-sm ps-2">
              <label htmlFor="durationSelect" className="text-black">
                {t(
                  "educationalProgram.tabAreaOfStudy.inputTitle.apprentice"
                )}
              </label>
              <select
                className="form-select c-form-control c-form-control-bg c-form-control-sm c-form-control-sm-height fw-semibold"
                value={apprenticeOffered}
                onChange={(e) => setApprenticeOffered(e.target.value)}
              >
                <option value="" selected>
                  {t(
                    "educationalProgram.tabAreaOfStudy.inputValue.all"
                  )}
                </option>
                <option value={true}>
                  {t(
                    "educationalProgram.tabAreaOfStudy.inputValue.offered"
                  )}
                </option>
                <option value={false}>
                  {t(
                    "educationalProgram.tabAreaOfStudy.inputValue.notOffered"
                  )}
                </option>
              </select>
            </div>
          </div>
        </div>
      )}

      {showPaginationComponent ?
        (
          <>
            <button
              onClick={() => setShowPaginationComponent(!showPaginationComponent)}
              className="btn btn-link text-theme font-small ps-2 m-0"
            >
              {t("educationalProgram.tabAreaOfStudy.paginationComponent.backButtonChart")}

            </button>
            <PaginationComponent
              // onBackClicked={onBackClicked}
              item={studyField}
              apprentice={apprenticeOffered}
              levelOfInstruction={selectedLevelOfInstruction}
              province={selectedProvince}
            />
          </>
        )
        :
        <div style={{ marginTop: "20px" }} className="chart-container" id="chart-container">
          <HighchartsReact highcharts={Highcharts} constructorType={'chart'} options={options} />
        </div>
      }

    </>
  );
};

export default HorizontalChartEducationalProgram;


// import React, { useEffect, useState, useRef, useCallback } from "react";
// import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";
// import API_URLS from "../../constants/apiUrls.ts";
// import httpClient from "../../services/httpClient.ts";
// import PaginationComponent from "./PaginationComponent.js";
// import { useTranslation } from "react-i18next";
// import { capitalizeString } from "../../services/commonFn";
// import { Loader } from "../Loader.tsx";

// const HorizontalChartEducationalProgram = () => {
//   const { t } = useTranslation();
//   const chartRef = useRef(null);
//   const [showLoader, setShowLoader] = useState(false);
//   const [provinces, setProvinces] = useState([]);
//   const [levelOfInstructions, setLevelOfInstructions] = useState([]);
//   const [selectedProvince, setProvince] = useState("");
//   const [selectedLevelOfInstruction, setLevelOfInstruction] = useState("");
//   const [apprenticeOffered, setApprenticeOffered] = useState("");
//   const [showPaginationComponent, setShowPaginationComponent] = useState(false);
//   const [studyField, setStudyField] = useState("");
//   const [options, setOptions] = useState({
//     chart: { type: "bar" },
//     title: { text: '', align: "left" },
//     xAxis: { categories: [] },
//     yAxis: { visible: false },
//     legend: { enabled: false },
//     series: [{
//       name: "Program(s)",
//       data: [],
//       pointWidth: 20,
//       borderRadius: 10,
//       color: "#E61E28",
//       dataLabels: {
//         enabled: true,
//         inside: true,
//         format: "{y}",
//         style: { color: "white", textOutline: "0px" }
//       },
//     }],
//     plotOptions: {
//       series: {
//         point: {
//           events: {
//             click: function () {
//               setShowPaginationComponent(prev => !prev);
//               setStudyField(this.item);
//             },
//           },
//         },
//         cursor: "pointer",
//       },
//     },
//   });

//   function debounce(fn, delay) {
//     let timer;
//     return function (...args) {
//       clearTimeout(timer);
//       timer = setTimeout(() => fn.apply(this, args), delay);
//     };
//   }

//   const fetchInitialData = useCallback(async () => {
//     setShowLoader(true);
//     try {
//       const urls = {
//         geographies: API_URLS.listGeographies,
//         levelOfInstruction: API_URLS.listLevelOfInstruction,
//       };

//       const [geographiesResponse, levelOfInstructionResponse] = await Promise.all([
//         httpClient({ method: "get", url: urls.geographies }),
//         httpClient({ method: "get", url: urls.levelOfInstruction }),
//       ]);

//       if (geographiesResponse?.result) {
//         setProvinces(geographiesResponse.result.geographies
//           .filter(item => item.name !== 'canada' && item.name !== 'geography')
//           .sort((a, b) => a.name.localeCompare(b.name)));
//       }

//       if (levelOfInstructionResponse.result) {
//         setLevelOfInstructions(levelOfInstructionResponse.result.results);
//       }
//     } catch (error) {
//       console.log(error);
//     } finally {
//       setShowLoader(false);
//     }
//   }, []);

//   const fetchData = useCallback(async () => {
//     setShowLoader(true);
//     try {
//       const url = API_URLS.countAreaOfStudy
//         .replace('{province}', selectedProvince)
//         .replace('{levelOfInstruction}', selectedLevelOfInstruction)
//         .replace('{apprentice}', apprenticeOffered);

//       const { result } = await httpClient({ method: "get", url });

//       if (result) {
//         const sortedResult = result.counts.sort((a, b) => a.count < b.count ? 1 : -1);
//         const categories = sortedResult.map(item => capitalizeString(t(item.studyField)));
//         const data = sortedResult.map(item => ({ y: item.count, item }));

//         setOptions(prevOptions => ({
//           ...prevOptions,
//           xAxis: { categories },
//           series: [{ ...prevOptions.series[0], data }],
//         }));
//       }
//     } catch (error) {
//       console.log(error);
//     } finally {
//       setShowLoader(false);
//     }
//   }, [selectedProvince, selectedLevelOfInstruction, apprenticeOffered, t]);

//   useEffect(() => {
//     fetchInitialData();
//   }, [fetchInitialData]);

//   useEffect(() => {
//     if (selectedProvince || selectedLevelOfInstruction || apprenticeOffered) {
//       fetchData();
//     }
//   }, [selectedProvince, selectedLevelOfInstruction, apprenticeOffered, fetchData]);

//   const handleProvinceChange = useCallback(
//     debounce((e) => setProvince(e.target.value), 300),
//     []
//   );

//   const handleLevelOfInstructionChange = useCallback(
//     debounce((e) => setLevelOfInstruction(e.target.value), 300),
//     []
//   );

//   const handleApprenticeOfferedChange = useCallback(
//     debounce((e) => setApprenticeOffered(e.target.value), 300),
//     []
//   );

//   return (
//     <>
//       <Loader show={showLoader} />
//       <h6 className="py-3 px-2">
//         {t('educationalProgram.tabAreaOfStudy.title')}
//       </h6>
//       {!showPaginationComponent && (
//         <div className="row gx-0">
//           <div className="col-md-3">
//             <div className="custom-form-group custom-form-group-sm ps-2">
//               <label htmlFor="provinceSelect" className="text-black">
//                 {t("educationalProgram.tabProvinceAndTerritory.paginationComponent.inputTitle.selectProvince")}
//               </label>
//               <select
//                 className="form-select c-form-control c-form-control-bg c-form-control-sm c-form-control-sm-height fw-semibold"
//                 value={selectedProvince}
//                 onChange={handleProvinceChange}
//               >
//                 <option value="">{t("educationalProgram.tabProvinceAndTerritory.paginationComponent.inputValue.all")}</option>
//                 {provinces.map(item => (
//                   <option key={item._id} value={item._id}>
//                     {capitalizeString(t(item.languageKey))}
//                   </option>
//                 ))}
//               </select>
//             </div>
//           </div>

//           <div className="col-md-3">
//             <div className="custom-form-group custom-form-group-sm ps-2">
//               <label htmlFor="levelOfInstructionSelect" className="text-black">
//                 {t("educationalProgram.tabAreaOfStudy.inputTitle.selectLevelOfInstruction")}
//               </label>
//               <select
//                 className="form-select c-form-control c-form-control-bg c-form-control-sm c-form-control-sm-height fw-semibold"
//                 value={selectedLevelOfInstruction}
//                 onChange={handleLevelOfInstructionChange}
//               >
//                 <option value="">{t("educationalProgram.tabProvinceAndTerritory.paginationComponent.inputValue.all")}</option>
//                 {levelOfInstructions.map(item => (
//                   <option value={item}>{capitalizeString(t(item))}</option>
//                 ))}
//               </select>
//             </div>
//           </div>

//           <div className="col-md-3">
//             <div className="custom-form-group custom-form-group-sm ps-2">
//               <label htmlFor="apprenticeSelect" className="text-black">
//                 {t("educationalProgram.tabAreaOfStudy.inputTitle.apprentice")}
//               </label>
//               <select
//                 className="form-select c-form-control c-form-control-bg c-form-control-sm c-form-control-sm-height fw-semibold"
//                 value={apprenticeOffered}
//                 onChange={handleApprenticeOfferedChange}
//               >
//                 <option value="">{t("educationalProgram.tabAreaOfStudy.inputValue.all")}</option>
//                 <option value={true}>{t("educationalProgram.tabAreaOfStudy.inputValue.offered")}</option>
//                 <option value={false}>{t("educationalProgram.tabAreaOfStudy.inputValue.notOffered")}</option>
//               </select>
//             </div>
//           </div>
//         </div>
//       )}

//       {showPaginationComponent ? (
//         <>
//           <button
//             onClick={() => setShowPaginationComponent(!showPaginationComponent)}
//             className="btn btn-link text-theme font-small ps-2 m-0"
//           >
//             {t("educationalProgram.tabAreaOfStudy.paginationComponent.backButtonChart")}
//           </button>
//           <PaginationComponent
//             item={studyField}
//             levelOfInstruction={selectedLevelOfInstruction}
//             province={selectedProvince}
//           />
//         </>
//       ) : (
//         <div style={{ marginTop: "20px" }} className="chart-container" id="chart-container">
//           <HighchartsReact highcharts={Highcharts} constructorType={'chart'} options={options} />
//         </div>
//       )}
//     </>
//   );
// };

// export default HorizontalChartEducationalProgram;

