import React, { useState, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Row, Col, Card, Button, Form } from 'react-bootstrap';
import httpClient from '../services/httpClient.ts';
import API_URLS from '../constants/apiUrls.ts';
import { ToastContainer, toast } from 'react-toastify';
import { FaDownload } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import moment from 'moment'

const TranslationManager = () => {
    const { t } = useTranslation()
    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null);
    const [translationFileInfo, setTranslationFileInfo] = useState(null)
    // const [selectedLanguage, setSelectedLanguage] = useState('en')

    const handleDownload = async (fileId) => {
       
        let url = API_URLS.downloadFile.replace(":fileId", fileId);
        httpClient({
            method: "get",
            url,
            responseType: "blob",
        }).then(({ result, error }) => {
            
            if (result) {
                try {
                    const blob = new Blob([result], { type: result.type });
                    // const fileName = filePath.split('_').slice(2).join("_")
                    const url = window.URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute("download", "Translation Template.csv");

                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    window.URL.revokeObjectURL(url);
                } catch (error) {
                    console.error('There has been a problem with your fetch operation:', error);
                }
            }
        })
    }

    const uploadFile = () => {
        const url = API_URLS.uploadTranslationFile

        let formData = new FormData();
        formData.append("file", file);
        
        httpClient({
            method: "post",
            url,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then(({ result, error }) => {
                if (result) {
                    setFile(null)
                    if (fileInputRef.current) {
                        fileInputRef.current.value = ''; // Reset the file input
                    }
                    toast.success(result.message)
                    window.location.reload()
                }
            })
    }

    // Handle file selection
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile); // Update the files array
    };

    useEffect(() => {
        let url = API_URLS.getTranslationFileInfo
        httpClient({
            method: "get",
            url
        }).then(({result, error}) => {
            if(result){
                setTranslationFileInfo(result?.fileDetails)
            }
        })
    },[])

    return (
        <>
        <ToastContainer/>
       
        <Row>
            <Card style={{ width: '100%', marginTop: "10px" }}>
                {/* <Card.Header as="h5">File Upload</Card.Header> */}
                <Card.Body>
                    <div className='d-flex justify-content-between'>
                        <div>
                        <h3>{t('textUploadTranslationFile')}</h3>
                        <p>{t('textLastUpdatedOn')}: {moment(translationFileInfo?.updatedAt).format("YYYY-MM-DD HH:MM:SS A")}</p>
                        </div>
                        <div className='d-flex gap-2'>
                            <h3>{t('textDownloadTemplate')}</h3>
                            <FaDownload style={{ marginRight: "10px", cursor: "pointer" }} onClick={() => handleDownload(translationFileInfo?._id)}/>
                        </div>
                    </div>

                    <Form.Group className="mt-3">
                        <Form.Control
                            type="file"
                            onChange={(e) => {
                                handleFileChange(e);
                                // Reset the input value to allow re-selecting the same file
                                e.target.value = null;
                            }}
                        />
                    </Form.Group>
                   
                    <div className="mt-3">
                        {file ? (
                            <>
                                <strong>{t('textSelectedFiles')}:</strong>

                                <ul style={fileListStyle}>
                                    {/* {files.map((file, index) => ( */}
                                    <li key={file?.name} style={fileItemStyle}>
                                        <span style={fileIconStyle}>📄</span> {file?.name}
                                    </li>
                                    {/* ))} */}
                                </ul>
                            </>
                        ) : ""}
                    </div>
                   
                    <div className="d-flex justify-content-end gap-1 mt-3">
                        <Button variant="secondary" onClick={() => setFile(null)}>
                            {t('translationButtonTitleReset')}
                        </Button>
                        <Button variant="primary" onClick={uploadFile}>{t('translationButtonTitleUploadFile')}</Button>
                    </div>
                </Card.Body>
            </Card>
        </Row>
        </>
    );
};

const fileListStyle = {
    listStyleType: 'none',
    paddingLeft: 0,
    marginTop: '10px',
};

const fileItemStyle = {
    background: '#f8f9fa', // Light background for each item
    padding: '8px 12px',
    marginBottom: '6px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
};

const fileIconStyle = {
    marginRight: '8px',
};

export default TranslationManager